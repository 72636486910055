<template>
  <div
    :style="[textColorCss, mobileTextColorCss, backgroundColorCss]"
    class="hero"
    :class="[
      hideMobile ? 'm--hide-hero' : '',
      hideDesktop ? 'd--hide-hero' : '',
      styleModifier ? 'sm--' + styleModifier : '',
    ]"
  >
    <div class="ratio" :class="['m--' + mobileSize, 'd--' + desktopSize]">
      <template v-if="bannerBorder && bannerBorder.length">
        <Banner
          orientation="top"
          class="banner-border banner-border--top"
          v-bind="bannerBorder"
        />
        <Banner
          orientation="right"
          class="banner-border banner-border--right"
          v-bind="bannerBorder"
        />
        <Banner
          orientation="bottom"
          class="banner-border banner-border--bottom"
          v-bind="bannerBorder"
        />
        <Banner
          orientation="left"
          class="banner-border banner-border--left"
          v-bind="bannerBorder"
        />
      </template>
      <template v-if="mobileVideo || desktopVideo">
        <video
          v-if="mobileVideo"
          ref="mobileVideo"
          autoplay
          playsinline
          muted
          loop
          :controls="mobileVideoControls"
          preload="auto"
          class="mobile-video"
        >
          <source :src="mobileVideo" />
        </video>
        <video
          v-if="desktopVideo"
          ref="desktopVideo"
          autoplay
          playsinline
          muted
          loop
          :controls="desktopVideoControls"
          preload="auto"
          class="desktop-video"
        >
          <source :src="desktopVideo" />
        </video>
      </template>
      <ResponsiveImage
        v-if="mobileImageFilename || desktopImageFilename"
        :lazyload="true"
        :class="[mobileVideo ? 'm--hide' : '', desktopVideo ? 'd--hide' : '']"
        class="hero-image"
        :mobile-src="mobileImageFilename"
        :desktop-src="desktopImageFilename"
        :alt="mobileImageAlt"
        :mobile-display-size="mobileDisplaySize"
        :desktop-display-size="desktopDisplaySize"
        :ratio="{ type: 'auto' }"
      />
      <mp-link
        v-if="$u(link) !== null"
        :to="$u(link)"
        class="link"
        :class="{ 'hover-effect': hoverEffect }"
      />
    </div>
    <div
      v-if="eyebrow || headline || ctas || $slots.headline || $slots.button"
      :class="[
        tint ? 'tint' : '',
        contentPosition,
        displayContentUnderHeroOnMobile ? 'below-on-mobile' : '',
      ]"
      class="hero-content"
    >
      <div v-if="eyebrow" class="eyebrow" v-html="nl2br(eyebrow.html)" />

      <component
        :is="headlineType"
        v-if="headline"
        class="headline"
        :class="[
          `m-${mobileHeadlineSize}`,
          `d-${headlineSize}`,
          (headline && desktopHeadline && desktopHeadline.html !== '') ||
          hideMobileHeadlineOnDesktop
            ? 'hide'
            : '',
        ]"
        v-html="nl2br(headline.html)"
      />
      <slot name="headline" />

      <component
        :is="headlineType"
        v-if="desktopHeadline && desktopHeadline.html !== ''"
        class="headline headline-desktop"
        :class="headlineSize"
        v-html="nl2br(desktopHeadline.html)"
      />

      <p
        v-if="text && text.html !== ''"
        class="text"
        v-html="nl2br(text.html)"
      />
      <div class="hero-button">
        <component
          :is="button.component"
          v-for="(button, i) in ctas"
          :key="`hero-button${i}${_uid}`"
          v-bind="button"
          :inherited-link="overwriteCtaLinks ? link : {}"
          :class="{
            'no-pointer-events': hoverEffect,
            'all-pointer-events': button.bambuserId,
          }"
          :initial-toggle-state="initialToggleState"
          @cta-toggle="onCtaToggle"
        />
        <slot name="button" />
      </div>
    </div>
  </div>
</template>

<script>
import { color } from '@made-people/centra-storyblok-nuxt-shared/lib/util/ColorHexCode'

export default {
  name: 'Hero',
  props: {
    hideMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileSize: {
      type: String,
      required: true,
    },
    mobileImage: {
      type: Object,
      required: false,
      default: null,
    },
    mobileVideo: {
      type: String,
      required: false,
      default: '',
    },
    mobileVideoControls: {
      type: Boolean,
      default: false,
    },
    textColorHex: {
      type: String,
      required: false,
      default: '#FFFFFF',
    },
    alt: {
      type: String,
      required: true,
    },
    link: {
      type: Object,
      required: false,
      default: () => {},
    },
    overwriteCtaLinks: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
    desktopSize: {
      type: String,
      required: true,
    },
    desktopImage: {
      type: Object,
      required: false,
      default: null,
    },
    desktopVideo: {
      type: String,
      required: false,
      default: '',
    },
    desktopVideoControls: {
      type: Boolean,
      default: false,
    },
    tint: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: '',
    },
    contentPosition: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: false,
      default: 'white',
    },
    eyebrow: {
      type: [String, Object],
      required: false,
      default: null,
    },
    headline: {
      type: [String, Object],
      required: false,
      default: null,
    },
    desktopHeadline: {
      type: [String, Object],
      required: false,
      default: null,
    },
    text: {
      type: [String, Object],
      required: false,
      default: null,
    },
    headlineSize: {
      type: String,
      required: false,
      default: 'h--large',
    },
    headlineType: {
      type: String,
      required: false,
      default: 'h2',
    },
    hideMobileHeadlineOnDesktop: {
      type: Boolean,
      default: false,
    },
    ctas: {
      type: Array,
      required: false,
      default: null,
    },
    bannerBorder: {
      type: Array,
      default: undefined,
    },
    hoverEffect: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    styleModifier: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     * Defaults to 100vw if not sent
     */
    mobileDisplaySize: {
      type: String,
      required: false,
      default: '100vw',
    },
    desktopDisplaySize: {
      type: String,
      required: false,
      default: '100vw',
    },
    index: {
      type: Number,
      required: false,
      default: undefined,
    },
    initialToggleState: {
      type: Boolean,
      default: true,
    },
    mobileHeadlineSize: {
      type: String,
      required: false,
      default: null,
    },
    mobileTextColor: {
      type: String,
      required: false,
      default: null,
    },
    displayContentUnderHeroOnMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      desktop: null,
    }
  },
  computed: {
    mobileImageFilename() {
      return this.mobileImage?.filename || ''
    },
    desktopImageFilename() {
      return this.desktopImage?.filename || ''
    },
    mobileImageAlt() {
      return this.mobileImage?.alt || ''
    },
    textColorCss() {
      return { '--text-color': this.textColorHex || this.textColor }
    },
    mobileTextColorCss() {
      return {
        '--text-color-mobile': this.textColorHex || this.mobileTextColor,
      }
    },
    backgroundColorCss() {
      return {
        '--bg-color': color(this.backgroundColor, this.backgroundColorHex),
      }
    },
  },
  mounted() {
    this.initDesktopQuery()

    if (this.mobileVideo) {
      this.playVideo(this.$refs.mobileVideo, this.mobileVideo)
    }
    if (this.desktopVideo) {
      this.playVideo(this.$refs.desktopVideo, this.desktopVideo)
    }
  },
  methods: {
    playVideo(ref, src) {
      const video = ref
      const m3u8 = /\.(m3u8)\?/i
      if (video) {
        // Check if source is HLS
        if (window.Hls && m3u8.test(src) && window.Hls.isSupported()) {
          const hls = new window.Hls()
          hls.loadSource(src)
          hls.attachMedia(video)
          hls.on(window.Hls.Events.MANIFEST_PARSED, () => {
            video.play()
          })
          // When the browser has built-in HLS support (check using `canPlayType`),
          // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
        } else if (
          m3u8.test(src) &&
          video.canPlayType('application/vnd.apple.mpegurl')
        ) {
          video.src = src
          video.addEventListener('loadedmetadata', () => {
            video.play()
          })
        } else {
          // If the source is something other than .m3u8, just play the source that was supplied
          video.play()
        }
      }
    },
    onCtaToggle(showProducts) {
      this.$emit('cta-toggle', showProducts)
    },
    nl2br(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    initDesktopQuery() {
      const desktopQuery = window.matchMedia('(min-width: 1024px)')
      this.desktop = desktopQuery.matches

      desktopQuery.addEventListener('change', (e) => {
        this.desktop = e.matches
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.hero {
  position: relative;
  overflow: hidden;
  --bg-color: transparent;
  background: var(--bg-color);

  &.m--hide-hero {
    display: none;
  }

  .mobile-video,
  .desktop-video {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    //display: inherit;
  }

  .desktop-video {
    display: none;
  }

  .hero-image {
    width: 100%;
    height: 100%;

    &::v-deep {
      picture {
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.m--hide {
      display: none;
    }
  }
  .ratio {
    position: relative;
    overflow: hidden;

    /**
   * Predefined Sizes
   */
    &.m--tall {
      @include aspect-ratio(320, 488);
    }
    &.m--wide {
      @include aspect-ratio(4, 3);
    }
    &.m--square {
      @include aspect-ratio(1, 1);
    }
    &.m--auto {
      .mobile-video,
      .desktop-video,
      > figure {
        position: relative !important;
        top: unset;
      }
      // Do nothing really...
    }
    &.m--full-reveal {
      height: calc((var(--vh, 1vh) * 100) - 200px);

      .ratio {
        overflow: hidden;
        height: 100%;
        width: 100%;
      }
    }
  }

  .link {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: $black;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.2s ease-out;
    &.hover-effect {
      @media (hover: hover) {
        &:hover {
          opacity: 0.3;
        }
      }
    }
  }

  .hero-content {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    color: var(--text-color-mobile, var(--text-color));
    z-index: 3;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    pointer-events: none;

    &.below-on-mobile {
      position: relative;
      top: unset;
      left: unset;
    }

    /**
     * Adds a tint to the bottom part of the hero
     */
    &.tint {
      &:after {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        left: 0px;
        bottom: 0px;
        background: radial-gradient(
          ellipse at center center,
          rgba(0, 0, 0, 1),
          transparent
        );
        mix-blend-mode: multiply;
        opacity: 0.5;
        z-index: 1;
        pointer-events: none;
      }
      &.below-on-mobile:after {
        display: none;
      }
    }

    // Headings
    // desktop size will be used if mobile is not defined
    // Desktop

    .d-h--tiny {
      @include h--tiny;
    }
    .d-h--x-small {
      @include h--x-small;
    }
    .d-h--small {
      @include h--small;
    }
    .d-h--medium {
      @include h--medium;
    }
    .d-h--large {
      @include h--large;
    }
    .d-h--x-large {
      @include h--x-large;
    }
    .d-display--x-large {
      @include display--x-large;
    }
    .d-display--huge {
      @include display--huge;
    }

    // Mobile

    .m-h--tiny {
      @include h--tiny;
    }
    .m-h--x-small {
      @include h--x-small;
    }
    .m-h--small {
      @include h--small;
    }
    .m-h--medium {
      @include h--medium;
    }
    .m-h--large {
      @include h--large;
    }
    .m-h--x-large {
      @include h--x-large;
    }
    .m-display--x-large {
      @include display--x-large;
    }
    .m-display--huge {
      @include display--huge;
    }

    /**
     * Content Positioning
     */
    &.left-top {
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
      &:after {
        background: radial-gradient(
          ellipse at left top,
          rgba(0, 0, 0, 1),
          transparent
        );
      }
    }
    &.center-top {
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      &:after {
        background: radial-gradient(
          ellipse at center top,
          rgba(0, 0, 0, 1),
          transparent
        );
      }
    }
    &.right-top {
      justify-content: flex-start;
      align-items: flex-end;
      text-align: right;
      &:after {
        background: radial-gradient(
          ellipse at right top,
          rgba(0, 0, 0, 1),
          transparent
        );
      }
    }
    &.left-center {
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      &:after {
        background: radial-gradient(
          ellipse at left center,
          rgba(0, 0, 0, 1),
          transparent
        );
      }
    }
    &.center-center {
      justify-content: center;
      align-items: center;
      text-align: center;
      &:after {
        background: radial-gradient(
          ellipse at center center,
          rgba(0, 0, 0, 1),
          transparent
        );
      }
    }
    &.right-center {
      justify-content: center;
      align-items: flex-end;
      text-align: right;
      &:after {
        background: radial-gradient(
          ellipse at right center,
          rgba(0, 0, 0, 1),
          transparent
        );
      }
    }
    &.left-bottom {
      justify-content: flex-end;
      align-items: flex-start;
      text-align: left;
      &:after {
        background: radial-gradient(
          ellipse at left bottom,
          rgba(0, 0, 0, 1),
          transparent
        );
      }
    }
    &.center-bottom {
      justify-content: flex-end;
      align-items: center;
      text-align: center;
      &:after {
        background: radial-gradient(
          ellipse at center bottom,
          rgba(0, 0, 0, 1),
          transparent
        );
      }
    }
    &.right-bottom {
      justify-content: flex-end;
      align-items: flex-end;
      text-align: right;
      &:after {
        background: radial-gradient(
          ellipse at right bottom,
          rgba(0, 0, 0, 1),
          transparent
        );
      }
    }

    .eyebrow {
      z-index: 2;
      &::v-deep {
        > br:last-of-type {
          display: none;
        }
      }
    }
    .headline {
      &,
      &.m-h--tiny,
      &.m-h--x-small,
      &.m-h--small,
      &.m-h--medium,
      &.m-h--large,
      &.m-h--x-large,
      &.m-display--x-large,
      &.m-display--huge,
      &.d-h--tiny,
      &.d-h--x-small,
      &.d-h--small,
      &.d-h--medium,
      &.d-h--large,
      &.d-h--x-large,
      &.d-display--x-large,
      &.d-display--huge {
        margin-top: 0.8rem;
        margin-bottom: 1rem;
        z-index: 2;
        &-desktop {
          display: none;
        }
        &::v-deep {
          > br:last-of-type {
            display: none;
          }
          p em {
            -webkit-text-stroke-color: var(
              --text-color-mobile,
              var(--text-color)
            );
          }
          a {
            text-decoration: none;
          }
        }
        &.small {
          &::v-deep p {
            @include h--small;
            margin: 0;
          }
        }
        &.medium {
          &::v-deep p {
            @include h--medium;
            margin: 0;
          }
        }
        &.large {
          &::v-deep p {
            @include h--large;
            margin: 0;
          }
        }
        &.x-large {
          &::v-deep p {
            @include h--x-large;
            margin: 0;
          }
        }
        &.huge {
          &::v-deep p {
            @include h--x-large;
            margin: 0;
          }
        }
      }
    }
    .text {
      z-index: 2;
      @include p--large;
      &::v-deep {
        > br {
          display: none;
        }
        p {
          &:first-of-type {
            margin-top: 0;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    .hero-button {
      z-index: 1;
      margin-top: 1.6rem;
      z-index: 2;
      .button {
        margin: 1.6rem 0;
        &:first-of-type,
        &:last-of-type {
          margin: 0;
        }
        &.no-pointer-events {
          pointer-events: none;
        }
        &.all-pointer-events {
          pointer-events: all;
        }
      }
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .hero {
    .ratio {
      &.m--auto {
        height: auto;
      }
      &.m--full-reveal {
        height: calc((var(--vh, 1vh) * 100) - 250px);
        .ratio {
          height: 100%;
          width: 100%;
        }
      }
    }

    .hero-content {
      .headline.hide {
        display: none;

        & + .headline-desktop {
          display: block;
        }
      }
      .eyebrow,
      .headline,
      .text,
      .hero-button {
        width: 90%;
        min-width: 18.4rem;
        max-width: 880px;
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .hero {
    &.m--hide-hero {
      display: block;
    }
    &.d--hide-hero {
      display: none;
    }
    .mobile-video {
      display: none;
    }
    .desktop-video {
      display: block;
    }

    .ratio {
      /**
     * Predefined Sizes
     */
      &.d--tall {
        height: unset;
        @include aspect-ratio(3, 4);
      }
      &.d--wide {
        height: unset;
        @include aspect-ratio(2, 1);
      }
      &.d--square {
        height: unset;
        @include aspect-ratio(1, 1);
      }
      &.d--auto {
        height: unset;
        @include aspect-ratio($destroy: true);
        .mobile-video,
        .desktop-video,
        > figure {
          position: relative !important;
          top: unset;
        }
      }
      &.d--full-reveal {
        @include aspect-ratio($destroy: true);
        height: calc((var(--vh, 1vh) * 100) - 250px);
        .ratio {
          height: 100%;
          width: 100%;
        }
      }
    }

    .hero-image {
      &.m--hide {
        display: block;
      }
      &.d--hide {
        display: none;
      }
    }

    .hero-content {
      color: var(--text-color, var(--text-color-mobile));
      padding: spacing('xlarge');
      z-index: 2;

      &.below-on-mobile {
        position: absolute;
        top: 0;
        left: 0;
      }
      &.tint {
        &.below-on-mobile:after {
          display: block;
        }
      }

      .headline {
        &,
        &.m-h--tiny,
        &.m-h--x-small,
        &.m-h--small,
        &.m-h--medium,
        &.m-h--large,
        &.m-h--x-large,
        &.m-display--x-large,
        &.m-display--huge,
        &.d-h--tiny,
        &.d-h--x-small,
        &.d-h--small,
        &.d-h--medium,
        &.d-h--large,
        &.d-h--x-large,
        &.d-display--x-large,
        &.d-display--huge {
          margin-top: 1.6rem;
        }
      }
      .button {
        min-width: 24rem;
        margin-top: 3.2rem;
      }

      // Headings
      // mobile size will be used if desktop is not defined
      // Mobile headings

      .m-h--tiny {
        @include h--tiny;
      }
      .m-h--x-small {
        @include h--x-small;
      }
      .m-h--small {
        @include h--small;
      }
      .m-h--medium {
        @include h--medium;
      }
      .m-h--large {
        @include h--large;
      }
      .m-h--x-large {
        @include h--x-large;
      }
      .m-display--x-large {
        @include display--x-large;
      }
      .m-display--huge {
        @include display--huge;
      }

      // Desktop headings

      .d-h--tiny {
        @include h--tiny;
      }
      .d-h--x-small {
        @include h--x-small;
      }
      .d-h--small {
        @include h--small;
      }
      .d-h--medium {
        @include h--medium;
      }
      .d-h--large {
        @include h--large;
      }
      .d-h--x-large {
        @include h--x-large;
      }
      .d-display--x-large {
        @include display--x-large;
      }
      .d-display--huge {
        @include display--huge;
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  // ...
}
</style>

<style lang="scss" scoped>
/**
 * Custom Sizes
 *
 * When the hero is placed within a grid
 * we need to tweak some of the attributes
 * in order to as much extent as possible
 * make sure that content fits
 *
 * In order to not have to override each setting
 * across breakpoints, these styles are not inherited
 */

// Mobile
@media screen and (max-width: $tablet - 1px) {
  .hero {
    /**
     * Mobile
     * 2 Columns
     */
    &.ms--51vw {
      &::v-deep .ribbon {
        padding: 0.6rem 1.2rem 0.6rem 1.8rem;
      }
      .hero-content {
        padding: 4.5vw;
        &::v-deep .eyebrow p {
          font-size: 2.5vw;
        }
        // &::v-deep .headline {
        //   &.large p {
        //     font-size: 4vw;
        //   }
        //   &.x-large p {
        //     font-size: 5vw;
        //   }
        //   &.huge p {
        //     font-size: 12vw;
        //   }
        // }
        .hero-button {
          &::v-deep .button {
            min-width: 20vw;
          }
        }
      }
    }

    /**
     * Mobile
     * 3 Columns
     */
    &.ms--34vw {
      &::v-deep .ribbon {
        display: none;
        padding: 0.6rem 1.2rem 0.6rem 1.8rem;
      }
      .hero-content {
        padding: 3vw;
        &::v-deep .eyebrow p {
          font-size: 1.5vw;
        }
        &::v-deep .headline {
          margin-top: 0.6rem;
          &.large p,
          &.x-large p {
            font-size: 3vw;
          }
          &.huge p {
            font-size: 8vw;
          }
        }
        .hero-button {
          &::v-deep .button {
            min-width: 25vw;
            .label {
              padding: 0 0.6rem;
            }
          }
        }
      }
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) and (max-width: $tablet-landscape - 1px) {
  /**
   * Tablet (Portrait)
   * 3 Columns
   */
  .hero {
    &.ms--34vw {
      &::v-deep .ribbon {
        padding: 0.6rem 2.2rem 0.6rem 2.8rem;
      }
      .hero-content {
        &::v-deep {
          .eyebrow p {
            //font-size: 1.8rem;
          }
          .headline {
            &.large p,
            &.x-large p {
              font-size: 3vw;
            }
            &.huge p {
              font-size: 8vw;
            }
          }
        }
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) and (max-width: $laptop - 1px) {
  /**
   * Tablet (Landscape)
   * 3 Columns
   */
  .hero {
    &.ds--34vw {
      .hero-content {
        padding: 3.3vw 2vw;
        &::v-deep {
          .eyebrow p {
            font-size: 1.2vw;
          }
          .headline {
            margin-top: 0.8vw;
            &.large p {
              //font-size: 3.2rem;
              font-size: 3vw;
            }
            &.x-large p {
              //font-size: 4rem;
              font-size: 3.5vw;
            }
            &.huge p {
              //font-size: 10.4rem;
              font-size: 8vw;
            }
          }
        }
        .button {
          min-width: 13vw;
          margin-top: 1.5vw;
        }
      }
    }
  }

  /**
   * Tablet (Landscape)
   * 4 Columns
   */
  .hero {
    &.ds--26vw {
      .hero-content {
        padding: 3.3vw 2vw;
        &::v-deep {
          .eyebrow p {
            font-size: 1vw;
          }
          .headline {
            margin-top: 0.8vw;
            &.large p {
              //font-size: 3.2rem;
              font-size: 2.5vw;
            }
            &.x-large p {
              //font-size: 4rem;
              font-size: 2.5vw;
            }
            &.huge p {
              //font-size: 10.4rem;
              font-size: 6vw;
            }
          }
        }
        .button {
          min-width: 13vw;
          margin-top: 1.5vw;
        }
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  /**
   * Laptop
   * 4 Columns
   */
  .hero {
    &.ds--26vw {
      .hero-content {
        padding: 3.3vw 2vw;
        &::v-deep {
          .eyebrow p {
            font-size: 1.4rem;
          }
          .headline {
            margin-top: 0.4vw;
            &.large p {
              //font-size: 3.2rem;
              font-size: 2vw;
            }
            &.x-large p {
              //font-size: 4rem;
              font-size: 2.5vw;
            }
            &.huge p {
              //font-size: 10.4rem;
              font-size: 6vw;
            }
          }
        }
      }
    }
  }
}

// Desktop
@media screen and (min-width: $desktop) {
}
</style>
